import React from "react";
import theme from "theme";
import { Theme, Image, Text, Icon, Box, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsDot } from "react-icons/bs";
import { FaPlusSquare } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
			Pixels Drift Autorent
			</title>
			<meta name={"description"} content={"Підготуйтеся до майбутньої дороги - Pixels Drift, досліджуйте комплексні рішення для водіння"} />
			<meta property={"og:title"} content={"Pixels Drift Autorent"} />
			<meta property={"og:description"} content={"Підготуйтеся до майбутньої дороги - Pixels Drift, досліджуйте комплексні рішення для водіння"} />
			<meta property={"og:image"} content={"https://pixelsdrift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://pixelsdrift.com/img/car-rental-glyph-two-color-icon-vector.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="--color-lightD1" quarkly-title="Hero-13">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Image
					src="https://pixelsdrift.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					width="60%"
					padding="80px 80px 80px 80px"
					lg-width="100%"
					md-padding="50px 50px 80px 50px"
					sm-padding="50px 25px 80px 25px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 25px 0px"
						sm-flex-direction="column"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 7px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							color="#061e11"
							sm-margin="0px 0 7px 0px"
							letter-spacing="1px"
						>
							12 РОКІВ ДОСВІДУ
						</Text>
						<Icon
							category="bs"
							icon={BsDot}
							size="24px"
							margin="0px 7px 0px 0px"
							sm-display="none"
						/>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="#061e11" letter-spacing="1px">
						ОРЕНДА АВТО
						</Text>
					</Box>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
						color="#061e11"
						lg-margin="0px 0px 35px 0px"
						md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
						sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
					>
						Pixels Drift 
					</Text>
					<Text margin="20px 0px 20px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="#061e11" letter-spacing="1px">
					Ваша пригода починається з повороту ключа в компанії Pixels Drift Autorent. Ми пишаємося тим, що забезпечуємо безперебійний процес прокату автомобілів, пристосований до потреб вашої подорожі. Незалежно від того, чи ви досліджуєте нові горизонти, чи перебуваєте у діловій поїздці, наш автопарк готовий зробити вашу подорож комфортною та безтурботною. Нехай Pixels Drift стане відправною точкою вашої дорожньої історії.
					</Text>
					<Link href="/contacts" color="--darkL1" font="normal 600 28px/1.2 --fontFamily-serifGeorgia">
						Замовити прокат &gt;&gt;
					</Link>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Чому варто обирати Pixels Drift?
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="70%"
					lg-width="100%"
					sm-text-align="left"
				>
					Вирушайте у свою наступну пригоду з Pixels Drift Autorent. Подорожуйте дорогами з нашим широким асортиментом транспортних засобів, від компактних автомобілів для міських поїздок до просторих позашляховиків для сімейного відпочинку. 
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaPlusSquare}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Безпроблемне бронювання
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Відчуйте простоту нашого процесу бронювання, розробленого для того, щоб ви могли швидше вирушити в дорогу.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaPlusSquare}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Конкурентоспроможні ціни
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Прозоре ціноутворення без прихованих платежів, що робить ваш досвід оренди простим і доступним.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaPlusSquare}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Гарантія якості
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Подорожуйте з упевненістю, знаючи, що кожен автомобіль проходить ретельну перевірку та мийку перед кожним прокатом.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="fa"
							icon={FaPlusSquare}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Персоналізоване обслуговування
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наша команда піклується про ваш комфорт і зручність, пропонуючи гнучкі варіанти отримання та повернення відповідно до вашого графіку.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://pixelsdrift.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Pixels Drift Autorent - де кожна подорож починається з легкості
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ми прагнемо надати вам надійний, чистий і готовий до експлуатації автомобіль, щоб ваші подорожі були настільки ж безтурботними, наскільки захоплюючими будуть ваші пункти призначення.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});